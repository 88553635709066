import './bootstrap.js';
/*
 * Welcome to your app's main JavaScript file!
 *
 * This file will be included onto the page via the importmap() Twig function,
 * which should already be in your base.html.twig.
 */
import './styles/app.css';

console.log('This log comes from assets/app.js - welcome to AssetMapper! 🎉');

document.addEventListener('DOMContentLoaded', function () {
    stickBubble(); // Llamamos a la función para establecer la posición inicial del elemento
    window.addEventListener('scroll', stickBubble); // Escuchamos el evento de scroll para actualizar la posición si es necesario
});

var bubble = document.querySelector('.whatsapp-bubble');
var container = document.querySelector('.whatsapp-container');

function stickBubble() {
    var containerRect = container.getBoundingClientRect();
    var containerTop = containerRect.top;
    var containerHeight = containerRect.height;
    var scrollY = window.scrollY || window.pageYOffset;
    var windowHeight = window.innerHeight;

    if (scrollY + windowHeight >= containerTop + containerHeight) {
        bubble.classList.add('visible'); // Agregamos la clase "visible" para mostrar el elemento con la transición
        bubble.style.position = 'fixed';
        bubble.style.bottom = '20px'; // Ajusta según tus necesidades
        bubble.style.left = '20px'; // Ajusta según tus necesidades
    } else {
        bubble.classList.remove('visible'); // Removemos la clase "visible" para ocultar el elemento con la transición
        bubble.style.position = 'absolute';
        bubble.style.bottom = '20px'; // Ajusta según tus necesidades
        bubble.style.left = '20px'; // Ajusta según tus necesidades
    }
}

// document.addEventListener('DOMContentLoaded', function () {
//     var messageSpan = document.querySelector('.whatsapp-message');
//     // Cambia el contenido del mensaje según tus necesidades
//     messageSpan.textContent = '¡Envíanos un mensaje personalizado!';
// });

/* --------------------------------------------------------
    scrollUp active
  -------------------------------------------------------- */
var scrollUpBtn = $('#scrollup');

$(window).scroll(function () {
    if ($(window).scrollTop() > 700) {
        scrollUpBtn.addClass('show');
    } else {
        scrollUpBtn.removeClass('show');
    }
});

scrollUpBtn.on('click', function (e) {
    e.preventDefault();
    $('html, body').animate({scrollTop: 0}, 300);
});